/* eslint-disable react/prop-types */
// setup the two forms, first one will be

import { Link } from "react-router-dom";

const RegisterForm = ({ updateFields, email, name, password }) => {
  return (
    <div className="flex  flex-1 flex-col justify-center px-6 pt-12 pb-6 lg:px-8 place-items-center">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Link to="/">
          <img
            className="mx-auto h-10 w-auto"
            src="/hargreaves-logo-icon.png"
            alt="Your Company"
          />
        </Link>
        <h2 className="mt-10 text-center text-2xl font-bold  text-gray-900">
          Create an account
        </h2>
      </div>
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm space-y-6">
        <div className="">
          <label
            htmlFor="email"
            className="block text-sm font-Roboto leading-6 text-gray-900"
          >
            Full Name
          </label>
          <div className="">
            <input
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={(event) => updateFields({ name: event.target.value })}
              autoComplete="name-new"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="">
          <label
            htmlFor="email"
            className="block text-sm font-Roboto leading-6 text-gray-900"
          >
            Email address
          </label>
          <div className="mt-2">
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(event) => updateFields({ email: event.target.value })}
              autoComplete="email-new"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <label
              htmlFor="password"
              className="block text-sm font-Roboto leading-6 text-gray-900"
            >
              Password
            </label>
          </div>
          <div className="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={(event) =>
                updateFields({ password: event.target.value })
              }
              autoComplete="password-new"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
