import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiFillInstagram } from "react-icons/ai";
import { GrLocation, GrDownload, GrCalendar } from "react-icons/gr";
import AuthContext from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

const classes = [
  {
    name: "Occlusion 1",
    title:
      "Principles of Occlusion, Jaw manipulations and occlusal record taking. Facebows and Articulators, including practicals on each other.",
    date: "Fri 29th Sept 2023",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Nigel Hargreaves",
    prole: "Doctor",
  },
  {
    name: "Occlusion 2",
    title:
      "Mounting casts, TMJ anatomy, pathology and tooth wear. Use of occlusal splints. Assessing Vertical Dimension and Re-organising Occlusion.",
    date: "Sat 30th Sept 2023",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Nigel Hargreaves",
    prole: "Doctor",
  },
  {
    name: "Direct Bonded Restorations",
    title:
      "Direct bonding systems - an update. Anterior and posterior direct aesthetic composite techniques.",
    date: "Fri 20th Oct 2023",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Dr Mark Tyzzer Smith",
    prole: "Doctor",
  },
  {
    name: "Cosmetic Composite Bonding ",
    title:
      "Planning and execution of cosmetic composite bonding and veneers. Learn the most up to date techniques to improve predictability and achieve amazing results.",
    date: "Sat 21st Oct 2023",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Dr Rajen Naggar",
    prole: "Doctor",
  },
  {
    name: "Crown Preps & Indirect Restorations / Impressions",
    title:
      "Principles of crown preparations. Anterior preps for different types of crowns. Vertical preps and technique. Impression techniques for crown and bridgework.",
    date: "Fri 10th Nov 2023",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Nigel Hargreaves",
    prole: "Doctor",
  },
  {
    name: "Crown Preps / 3D Scanning / Labs / Materials",
    title:
      "Posterior crown preparations and indirect adhesive restoration preps. Crown cementation and cements available. Lab materials and communication. 3D intra-oral scanning.",
    date: "Sat 11th Nov 2023",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Nigel Hargreaves",
    prole: "Doctor",
  },
  {
    name: "Smile Design / Veneers",
    title:
      "Principles of smile design. Preps for different situations to achieve predictable aesthetic results. Diagnostic work, planning provisionalisation and cementation",
    date: "Fri 8th Dec 2023",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Nigel Hargreaves",
    prole: "Doctor",
  },
  {
    name: "Perio / Soft Tissue / Crown Lengthening",
    title:
      "Update on periodontal classification, diagnosis and treatment. Soft tissue corrections, enhancements and crown lengthening.",
    date: "Sat 9th Dec 2023",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Dr Amit Patel",
    prole: "Doctor",
  },
  {
    name: "Bridges",
    title:
      "Principles of bridge design. Practical to prepare bridges for different situations and for different materials. Conventional and adhesive.",
    date: "Fri 12th Jan 2024",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Nigel Hargreaves",
    prole: "Doctor",
  },
  {
    name: "Dental Implants",
    title:
      "On overview of Dental Implants. How to restore simple cases. Treatment options, costs and maintenance of implants in general practice.",
    date: "Sat 13th Jan 2024",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Nigel Hargreaves",
    prole: "Doctor",
  },
  {
    name: "Dentures",
    title:
      "How to improve full and partial denture predictability. Clinical and Lab techniques. Partial denture options that are available for different situations.",
    date: "Fri 9th Feb 2024",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Nigel Hargreaves",
    prole: "Doctor",
  },
  {
    name: "Treatment Planning",
    title:
      "Treatment planning multi-disciplinary cases to formulate treatment plans visit by visit and give accurate costings",
    date: "Sat 10th Feb 2024",
    location: "Address",
    download: "Download Course Materials",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    pname: "Nigel Hargreaves",
    prole: "Doctor",
  },
];

const stats = [
  { name: "Location", value: "Bradford" },
  { name: "Sessions", value: "12 days" },
  { name: "CPD", value: "84 hours" },
  { name: "Dates", value: "Sept 2023 - Feb 2024" },
];

const people = [
  {
    name: "Nigel Hargreaves",
    role: "Senior Developer",
    imageUrl: "/Nigel.jpg",
    bio: "Praesentium iure error aliquam voluptas ut libero. Commodi placeat sit iure nulla officiis. Ut ex sit repellat tempora. Qui est accusamus exercitationem natus ut voluptas. Officiis velit eos ducimus.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Dr Mark Tyzzer Smith",
    role: "Senior Developer",
    imageUrl: "mark.jpeg",
    bio: "Praesentium iure error aliquam voluptas ut libero. Commodi placeat sit iure nulla officiis. Ut ex sit repellat tempora. Qui est accusamus exercitationem natus ut voluptas. Officiis velit eos ducimus.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Dr Rajen Naggar",
    role: "Senior Developer",
    imageUrl: "Dr_Rajen_Nagar.jpeg",
    bio: "Praesentium iure error aliquam voluptas ut libero. Commodi placeat sit iure nulla officiis. Ut ex sit repellat tempora. Qui est accusamus exercitationem natus ut voluptas. Officiis velit eos ducimus.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Dr Amit Patel",
    role: "Senior Developer",
    imageUrl:
      "https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    bio: "Billo baggi billi baggo bagge billi balle bago iure error aliquam voluptas ut libero. Commodi placeat sit iure nulla officiis. Ut ex sit repellat tempora. Qui est accusamus exercitationem natus ut voluptas. Officiis velit eos ducimus.",
    twitterUrl: "#",
    linkedinUrl: "#",
  },
];

const INIT_DATA = {
  gdc_number: "",
  phone_number: "",
  occupation: "",
  country: "",
  course_name: "12_day_restorative_course",
};

function Academy() {
  const [course, setCourse] = useState("Overview");
  const [open, setOpen] = useState(false);
  const [enrolled, setEnrolled] = useState("notApplied");
  const [formInput, setFormInput] = useState(INIT_DATA);
  function updateFields(fields) {
    setFormInput((prev) => {
      return { ...prev, ...fields };
    });
  }
  const [loggedIn, setLoggedIn] = useState(true);
  const { user, setUser } = useContext(AuthContext);
  // create a get enrollments by userId
  function getEnrollmentByUserId() {
    user &&
      axios
        .get("https://api.nigelhargreaves.co.uk/course/user/enrollment", {
          withCredentials: true,
        })
        .then((response) => {
          console.log(response)
          const approval = response.data[0].isApproved;
          console.log(response.data);
          const enrollmentRequest = user.enrollments.includes(
            response.data[0].course_name
          );
          approval && enrollmentRequest
            ? setEnrolled("Accepted")
            : !approval && enrollmentRequest
            ? setEnrolled("Applied")
            : setEnrolled("notApplied");
        })
        .catch((e) => console.log(e));
  }
  useEffect(() => {
    let isMounted = true;
    isMounted && user ? setLoggedIn(true) : setLoggedIn(false);
    isMounted ? getEnrollmentByUserId() : console.log("lol");
    return () => {
      isMounted = false;
    };
  }, [user]);
  const cancelButtonRef = useRef(null);
  async function onSubmit(e) {
    e.preventDefault();
    console.log(formInput);
    try {
      const response = await axios.post(
        "https://api.nigelhargreaves.co.uk/course/enroll",
        formInput,
        {
          withCredentials: true,
        }
      );
      console.log(response.data.user, response.data.enrollment);
      if (response.data.enrollment) {
        setUser(response.data.user);
        if (response.data.success) {
          setOpen(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      {/*Enrollmentform*/}
      {user && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <h2 className="text-center text-2xl font-bold leading-9 text-gray-900">
                      Enroll for the course
                    </h2>
                    <p className="mt-2 text-sm text-gray-700">
                      Course Coordinator: Joanne O’Shea <br />
                      Email:{" "}
                      <a
                        href="mailto:joanne@nigelhargreaves.co.uk"
                        className="text-indigo-600 hover:underline"
                      >
                        joanne@nigelhargreaves.co.uk
                      </a>{" "}
                      <br />
                      Tel: 07508794114
                    </p>

                    <form className="mt-4" onSubmit={onSubmit}>
                      <label
                        htmlFor="name"
                        className="block text-sm font-Roboto leading-6 mt-6 text-gray-900"
                      >
                        Name (as per GDC register)
                      </label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={user.name}
                        disabled
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                      />

                      <label
                        htmlFor="email"
                        className="block text-sm font-Roboto leading-6 mt-4 text-gray-900"
                      >
                        Email address
                      </label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        value={user.email}
                        disabled
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                      />

                      <label
                        htmlFor="gdc_number"
                        className="block text-sm font-Roboto leading-6 mt-4 text-gray-900"
                      >
                        GDC Number
                      </label>
                      <input
                        id="gdc_number"
                        name="gdc_number"
                        type="text"
                        onChange={(e) =>
                          updateFields({ gdc_number: e.target.value })
                        }
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                      />

                      <label
                        htmlFor="phone_number"
                        className="block text-sm font-Roboto leading-6 mt-4 text-gray-900"
                      >
                        Contact telephone number
                      </label>
                      <input
                        id="phone_number"
                        name="phone_number"
                        type="tel"
                        required
                        onChange={(e) =>
                          updateFields({ phone_number: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                      />

                      <label
                        htmlFor="occupation"
                        className="block text-sm font-Roboto leading-6 mt-4 text-gray-900"
                      >
                        Occupation
                      </label>
                      <input
                        id="occupation"
                        name="occupation"
                        type="text"
                        required
                        onChange={(e) =>
                          updateFields({ occupation: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                      />

                      <label
                        htmlFor="country"
                        className="block text-sm font-Roboto leading-6 mt-4 text-gray-900"
                      >
                        Country of residence
                      </label>
                      <input
                        id="country"
                        name="country"
                        type="text"
                        required
                        onChange={(e) =>
                          updateFields({ country: e.target.value })
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                      />

                      <div className="mt-4 flex items-center">
                        <input
                          id="consent"
                          name="consent"
                          type="checkbox"
                          required
                          className="mr-2"
                        />
                        <label
                          htmlFor="consent"
                          className="text-sm font-Roboto leading-6 text-gray-900"
                        >
                          I allow personal data collection for storage for
                          marketing purposes
                        </label>
                      </div>
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="submit"
                          className="inline-flex w-full justify-center font-Roboto rounded-md bg-black px-3 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                          // onClick={() => {
                          //   setOpen(false), setEnrolled("Applied");
                          // }}
                        >
                          Enroll
                        </button>
                        <button
                          type="button"
                          className=" mt-3 inline-flex w-full font-Roboto justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {/* header */}
      <div className="bg-gray-100 mt-20 sm:px-8 py-20 sm:py-28 lg:px-0">
        <div className="  text-left px-8 mx-auto max-w-7xl ">
          <h2 className="text-4xl  font-Cormorant font-bold text-black sm:text-4xl">
            12 Day Restorative Course
          </h2>
          <p className="mt-6 text-lg lg:w-2/5 sm:w-4/5 leading-8 font-Roboto text-gray-700">
            Ccomprehensively cover all aspects of restorative dentistry in an
            easy to digest format of short lectures, with lots of time spent on
            directed practical exercises on the phantom heads.
          </p>
          {enrolled === "Applied" && (
            <button className=" bg-gray-300 text-gray-700 font-Roboto px-4 py-2 mt-6">
              Enrollment Status: Pending
            </button>
          )}
          {enrolled === "notApplied" &&
            (user ? (
              <button
                onClick={() => setOpen(true)}
                className="bg-black text-white  px-4 py-2 mt-6"
              >
                Enroll Now
              </button>
            ) : (
              <button className="bg-black text-white font-Roboto px-4 py-2 mt-6">
                <Link to="/login">Enroll Now</Link>
              </button>
            ))}
          {enrolled === "Accepted" && (
            <div>
              <h4 className="sr-only">Status</h4>
              <div className="mt-10" aria-hidden="true">
                <div className="overflow-hidden w-2/5 rounded-full bg-gray-200">
                  <div
                    className="h-2 rounded-full bg-green-600"
                    style={{ width: "25%" }}
                  />
                </div>
                <div className="mt-3 hidden grid-cols-4 text-sm font-Roboto text-gray-600 sm:grid">
                  <div className="text-green-800">
                    3 out of 12 classes completed
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* stats*/}
      <div className="bg-[#262626] px-4">
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 gap-px  sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.name} className="bg-[#262626] py-6 ">
                <p className="text-sm  font-medium font-Roboto leading-6 text-gray-400">
                  {stat.name}
                </p>
                <p className="mt-2 flex items-baseline gap-x-2">
                  <span className="text-3xl font-medium text-white">
                    {stat.value}
                  </span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* information*/}
      <div className=" mx-auto px-4 max-w-7xl">
        <div className=" flex space-x-6 pt-5">
          <p
            onClick={() => setCourse("Overview")}
            className={`whitespace-nowrap border-b-2 px-4 pb-4 text-sm font-medium${
              course === "Overview"
                ? "text-black border-black"
                : "text-gray-400"
            }`}
          >
            Overview
          </p>
          <p
            onClick={() => setCourse("Schedule")}
            className={`whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium ${
              course === "Schedule"
                ? "text-black border-black"
                : "text-gray-400"
            }`}
          >
            Schedule
          </p>
          <p
            onClick={() => setCourse("Instructors")}
            className={`whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium ${
              course === "Instructors"
                ? "text-black border-black"
                : "text-gray-400"
            }`}
          >
            Instructors
          </p>
        </div>
      </div>
      {course === "Overview" && (
        <>
          <div className="bg-white px-6 py-12 lg:px-8">
            <div className="mx-auto max-w-7xl text-base leading-7 text-gray-700">
              <h1 className="mt-2 text-3xl font-bold  text-gray-900 sm:text-2xl">
                Course Overview
              </h1>
              <p className="mt-4 text-lg max-w-3xl font-Roboto leading-8">
                Our aim is to deliver two things. Firstly, the SKILLS to elevate
                your restorative dentistry, and secondly, the CONFIDENCE to take
                what you have learned and apply it in your working practice, the
                very next day. We comprehensively cover all aspects of
                restorative dentistry in an easy to digest format of short
                lectures, with lots of time spent on directed practical
                exercises on the phantom heads. Nigel is an approachable teacher
                and will be on hand, during, and after the course, to give
                advice on your cases. This course is designed for all dentists,
                from newly qualified, right up to the veterans amongst you, to
                take on more challenging cases, confidently and ethically
              </p>

              <div className="mt-12 max-w-3xl">
                <h1 className="mt-2 text-3xl font-bold  text-gray-900 sm:text-2xl">
                  The course is:
                </h1>
                <ul
                  role="list"
                  className="mt-4 max-w-3xl font-Roboto space-y-4 text-gray-600"
                >
                  <li className="flex gap-x-3">
                    <CheckCircleIcon
                      className="mt-1 h-5 w-5 flex-none  text-black"
                      aria-hidden="true"
                    />
                    <span>
                      Largely practical with very little time spent lecturing
                      and more time spent in the phantom head room refining your
                      skills and gaining much practical experience as possible
                      under supervision.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <CheckCircleIcon
                      className="mt-1 h-5 w-5 flex-none text-black"
                      aria-hidden="true"
                    />
                    <span>Based on the Tell-Show-Do approach to learning.</span>
                  </li>
                  <li className="flex gap-x-3">
                    <CheckCircleIcon
                      className="mt-1 h-5 w-5 flex-none text-black"
                      aria-hidden="true"
                    />
                    <span>Evidence-based.</span>
                  </li>
                </ul>
              </div>
              <div className="mt-12 max-w-3xl">
                <h1 className="mt-2 text-3xl font-bold  text-gray-900 sm:text-2xl">
                  The course fee includes:
                </h1>
                <ul
                  role="list"
                  className="mt-4 max-w-3xl font-Roboto space-y-4 text-gray-600"
                >
                  <li className="flex gap-x-3">
                    <CheckCircleIcon
                      className="mt-1 h-5 w-5 flex-none text-black"
                      aria-hidden="true"
                    />
                    <span>
                      All presentations are given on a CD memory stick to all
                      delegates for each course day.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <CheckCircleIcon
                      className="mt-1 h-5 w-5 flex-none text-black"
                      aria-hidden="true"
                    />
                    <span>
                      Practical manuals are given to each delegate for each day
                      for use in practice afterwards
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <CheckCircleIcon
                      className="mt-1 h-5 w-5 flex-none text-black"
                      aria-hidden="true"
                    />
                    <span>
                      Full product and materials back up with product re-order
                      catalogues for all materials and burrs used.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <CheckCircleIcon
                      className="mt-1 h-5 w-5 flex-none text-black"
                      aria-hidden="true"
                    />
                    <span>
                      Initial burr kits supplied at the start of the course for
                      all practical work, which are referenced throughout the
                      course.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <CheckCircleIcon
                      className="mt-1 h-5 w-5 flex-none text-black"
                      aria-hidden="true"
                    />
                    <span>
                      Phantom head jaws included in course free to take away at
                      the end of the course to use as patient demonstration
                      models.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
      {course === "Schedule" && (
        <>
          <div className="mx-auto px-4 max-w-7xl my-12">
            <ul
              role="list"
              className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-2"
            >
              {classes.map((classes) => (
                <li
                  key={classes.date}
                  className="col-span-1 divide-y divide-gray-200 bg-[#fafafa] border border-grey-100"
                >
                  <div className="flex w-full items-center justify-between space-x-6 px-6 pt-6 pb-4 ">
                    <div className="flex-1 ">
                      <div className="flex items-center space-x-3">
                        <h3 className=" text-md font-bold font-Roboto text-gray-900">
                          {classes.name}
                        </h3>
                      </div>
                      <p className="mt-1 text-sm font-Roboto text-gray-500">
                        {classes.title}
                      </p>
                      <div className="relative flex items-center space-x-3 mt-3">
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={classes.imageUrl}
                            alt=""
                          />
                        </div>
                        <div className="min-w-0 flex-1">
                          <div href="#" className="focus:outline-none">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            <p className="text-sm font-medium font-Roboto text-gray-900">
                              {classes.pname}
                            </p>
                            <p className="truncate text-sm font-Roboto text-gray-500">
                              {classes.prole}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="-mt-px flex ">
                      {loggedIn ? (
                        <div className="">
                          <div className="flex ">
                            <a className="relative font-Roboto -mr-px inline-flex  items-left pl-6 gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700">
                              <GrCalendar
                                className="h-4 w-5  text-gray-400"
                                aria-hidden="true"
                              />
                              {classes.date}
                            </a>
                          </div>
                          <div className="">
                            <a className="relative font-Roboto -mr-px inline-flex items-left pl-6 gap-x-3 rounded-bl-lg border border-transparent pb-4 text-sm font-medium text-gray-700">
                              <GrLocation
                                className="h-5 w-5 text-gray-400 "
                                aria-hidden="true"
                              />
                              {classes.location}
                            </a>
                          </div>
                          <div className=" text-blue-800 ">
                            <button className="relative font-Roboto -mr-px inline-flex items-left pl-6 gap-x-3 rounded-bl-lg border border-transparent pb-4 text-sm font-medium  text-blue-800 ">
                              <GrDownload
                                className="h-4 w-5 text-blue-800 "
                                aria-hidden="true"
                              />
                              {classes.download}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="flex w-0 flex-1">
                          <a className="relative font-Roboto -mr-px inline-flex w-0 flex-1 items-left pl-6 gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700">
                            <GrCalendar
                              className="h-5 w-5  text-gray-400"
                              aria-hidden="true"
                            />
                            {classes.date}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      {course === "Instructors" && (
        <>
          <div className="bg-white px-4 py-12 md:py-12 lg:py-12">
            <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20  xl:grid-cols-3">
              <ul
                role="list"
                className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2"
              >
                {people.map((person) => (
                  <li key={person.name}>
                    <img
                      className="aspect-[1/1] w-full object-cover"
                      src={person.imageUrl}
                      alt=""
                    />
                    <h3 className="mt-6 text-lg font-semibold font-Roboto leading-8 text-gray-900">
                      {person.name}
                    </h3>
                    <p className="text-base leading-7 font-Roboto text-gray-600">
                      {person.role}
                    </p>
                    <p className="mt-4 text-base font-Roboto leading-7 text-gray-600">
                      {person.bio}
                    </p>
                    <ul role="list" className="mt-6 flex gap-x-6">
                      <li>
                        <a
                          href={person.linkedinUrl}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">LinkedIn</span>
                          <svg
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href={person.twitterUrl}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">Twitter</span>
                          <svg
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href={person.instagramUrl}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">Instagram</span>
                          <AiFillInstagram className=" h-5 w-5 " />
                        </a>
                      </li>
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Academy;
