import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";

const faqs = [
  {
    question: "What is a dental implant?",
    answer:
      "A dental implant is a titanium screw that is placed directly into your jawbone, replacing your missing natural tooth root. After it is fitted, the natural bone surrounding a dental implant will fuse to the dental implant, holding it securely in place. A false tooth (dental crown) is then fitted on top of the dental implant which is customised to match the colour and shape of your natural teeth. This will then leave you with a fixed solution and a natural looking smile."
  },
  {
    question: "Am I suitable for dental implants?",
    answer:
      "Most adults are suitable for dental implants but there can be some exceptions. Every patient case differs and your dentist will discuss and assess with you in detail the solutions available to you during your initial consultation. If you are an unsuitable candidate for dental implants, your dentist will discuss alternative treatments to replace missing teeth."
  },
  {
    question: "What are the benefits of dental implants?",
    answer:
      "Natural looking solution. Fixed solution and long lasting. Prevent future bone loss. Enjoy your favourite foods. Regain the confidence to smile."
  },
  {
    question: "How much does a dental implant cost?",
    answer:
      "Dental implants start from £2500. The cost depends on your bespoke treatment plan and can differ with each individual patient as each case is different. You will be given a bespoke treatment plan tailored to yourself with a clear breakdown of the costs involved prior to you starting your treatment. Finance options are available if you wish to spread the cost (subject to credit approval)."
  },
  {
    question: "Is a dental implant a painful procedure?",
    answer:
      "Dental implants are placed under local anaesthetic. This will numb the mouth in order to carry out the treatment. Patients may feel some discomfort once the anaesthetic wears off which can sometimes last a few days. You may also notice some bruising and swelling which can last up to a week in some cases. This is normal following treatment and can be managed at home using over the counter medications such as paracetamol or ibuprofen. If you experience severe pain then you will be given emergency contact details following our treatment and you can contact your dentist for advice and reassurance. Some patients who are extremely nervous may be offered sedation to help you relax during the procedure. Speak with your dentist if you would like to discuss this option."
  },
  {
    question: "How does a dental implant work?",
    answer:
      "A dental implant is a titanium screw which is placed into your jawbone. It is made of titanium which makes them biocompatible. This means that our bodies accept dental implants and your natural bone will attach onto the implant. This process is called osseointegration. This integration process usually takes around 8-16 weeks. Once osseointegration is achieved you are then ready to have your false tooth (crown) placed and fixed to the dental implant."
  },
  {
    question: "Are dental implants suitable for everyone?",
    answer:
      "Most people are suitable for dental implant treatment. However in some cases the risk of failure is higher in some patients. For example, people who have some medical conditions such as uncontrolled diabetes. Some lifestyle habits may also affect the success of dental implants eg; smoking, poor gum health and other medications. In some cases patients may require additional treatments such as a bone graft or sinus lifting. This is required when there is insufficient bone available in the jaw to support a dental implant."
  }
];

export default function FAQAbout() {
  return (
    <div className="bg-[#F7F7F7]">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 ">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold font-Roboto leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 font-Roboto text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
