import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Alert from "./components/Alert";
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";

export default function App() {
  const [jwtToken, setJwtToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClassName, setAlertClassName] = useState("hidden");
  const [user, setUser] = useState(null);

  const location = useLocation();
  console.log(user);
  return (
    <main className=" bg-contain font-Cochin">
      {location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/dashboard" ? (
        ""
      ) : (
        <Navbar />
      )}
      <hr />
      <article className="">
        <Alert
          message={alertMessage}
          className={alertClassName}
          setAlertMessage={setAlertMessage}
        />
        <Outlet
          context={{
            user,
            setUser,
            setJwtToken,
            jwtToken,
            setAlertClassName,
            setAlertMessage
          }}
        />
      </article>
      {location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/dashboard" ? (
        ""
      ) : (
        <Footer />
      )}

    </main>
  );
}
