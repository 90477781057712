import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const Course = () => {
  const [course, setCourse] = useState({});
  const navigate = useNavigate();
  let { id } = useParams();
  useEffect(() => {
    let myCourse = {
      id: 1,
      title: "Dental Course 1",
      release_date: "24-09-2023",
      runtime: 116,
      grade: "221",
      description: "This is a dental course",
    };
    setCourse(myCourse);
  }, [id]);
  return (
    <div className="text-center flex flex-col">
      
     <button onClick={() => navigate(-1)}>go back</button>
      <h2 className=" font-bold text-xl">{course.title}</h2>

      <small className="">
        <em>
          {course.title}, {course.release_date}, {course.grade}
        </em>
      </small>
      <p className="">{course.description}</p>
    </div>
  );
};

export default Course;
