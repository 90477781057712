import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline' 
import {GrMail} from "react-icons/gr";
import {FaPhoneAlt} from "react-icons/fa";
import {PiInstagramLogoFill} from "react-icons/pi";

export default function Contact() {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 " />
      </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className=" px-6 py-16 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="mx-auto max-w-lg">
            <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl">Get in touch</h2>
            <p className="mt-3 text-md font-Roboto leading-6 text-gray-500">
              Contact Joanne O’Shea - Course Coordinator / PA for any inquiries or information.
            </p>
            <dl className="mt-8 text-base font-Roboto text-gray-800">
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <GrMail className="h-5 w-5 flex-shrink-0 " aria-hidden="true" />
                  <span className="ml-3">joanne@nigelhargreaves.co.uk</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <FaPhoneAlt className="h-5 w-5 flex-shrink-0 " aria-hidden="true" />
                  <span className="ml-3">07508794114</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Instagram</dt>
                <dd className="flex">
                  <PiInstagramLogoFill className="h-6 w-6 flex-shrink-0 " aria-hidden="true" />
                  <span className="ml-2">@drnigelhargreaves</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white sm:px-0 sm:py-6  lg:col-span-3 lg:px-8 lg:py-24 xl:pl-12">
          <div className="mx-auto max-w-lg lg:max-w-none">
          <form action="#" method="POST" className="px-6 lg:px-8 ">
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label htmlFor="first-name" className="block text-sm font-Roboto leading-6 text-gray-900">
                  First name
                </label>
                <div className="mt-2.5">
                  <input
                  required
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="last-name" className="block text-sm font-Roboto leading-6 text-gray-900">
                  Last name
                </label>
                <div className="mt-2.5">
                  <input
                  required
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-Roboto leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                  required
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="phone-number" className="block text-sm font-Roboto leading-6 text-gray-900">
                  Phone number
                </label>
                <div className="mt-2.5">
                  <input
                  required
                    type="tel"
                    name="phone-number"
                    id="phone-number"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-sm font-Roboto leading-6 text-gray-900">
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                  required
                    name="message"
                    id="message"
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                    defaultValue={''}
                  />
                </div>
              </div>
            </div>
            <div className="my-8 flex justify-end">
              <button
                type="submit"
                className="rounded-md w-full bg-black hover:bg-[#404040] px-3.5 py-2.5 text-center text-sm font-Roboto text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Send message
              </button>
            </div>
          </div>
        </form>
          </div>
        </div>
      </div>
    </div>
  )
}
