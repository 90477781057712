/* eslint-disable react/prop-types */
import { createContext, useEffect, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
    useEffect(() => {
        if(!user) {
            localStorage.removeItem("user");
        } else {
            localStorage.setItem("user", JSON.stringify(user));
        }
    },[user])
    return (
        <AuthContext.Provider value={{ auth, setAuth, user, setUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;