import { Link } from "react-router-dom";

const Courses = () => {
  return (
    <div className="text-center ">
      <h2 className=" font-bold text-xl">Courses folder</h2>
      <Link to={`/course/0`}>Course 0</Link>
    </div>
  );
};

export default Courses;
