import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import TreatmentEnquiryForm from "../TreatmentEnquiryForm";
import Testimonials from "../Testimonials";
import FAQs from "../FAQs"


const tabs = [
  {
    name: "Dental Implants",
    features: [
      {
        name: "Dental Implants Overview",
        description:
          "Dental implants are often the ideal way to replace missing teeth. A dental implant is a titanium fixture that is placed into the jaw bone in place of a natural tooth. This is usually carried out in a single visit under local anaesthetic. There needs to be a good quality and quantity of bone in order for a dental implant to be successful. In some cases patients may require a bone augmentation.",
        imageSrc: "/BA-1.png",
        imageAlt: "Dental Implants"
      },
      {
        name: "Implant Treatments",
        description:
          "Implant treatments is a lengthy treatment from start to finish, usually an integration period of around 3-6 months is required before final crowns and bridges are attached. During the integration (healing phase) some cases can have an immediate temporary crown/bridge or a removable prosthesis to retain aesthetics and function.",
        imageSrc: "/BA-2.png",
        imageAlt: "Implant Treatments"
      },
      {
        name: "Same Day Implants",
        description:
          "Same day implants. Full arch implants can be placed and the implants can be connected with a full arch fixed bridge on the same day. Same day teeth and All on 4 concepts are used to immediately give patients a fixed restoration on the same day as their existing failing teeth are removed.",
        imageSrc: "/BA-3.png",
        imageAlt: "Same Day Implants"
      }
      // Additional feature sections for 'Dental Implants' can be added here
    ]
  },
  {
    name: "Porcelain Veneers",
    features: [
      {
        name: "Porcelain Veneers Overview",
        description:
          "Porcelain veneers are custom made shells of porcelain crafted by ceramists in the dental laboratory and are adhesively bonded to the surface of your natural teeth. They are commonly used to repair chips and fractures, cover staining, close spaces and for smile makeovers.",
        imageSrc: "/BA-4.png",
        imageAlt: "Porcelain Veneers"
      },
      {
        name: "Process & Care",
        description:
          "Porcelain is the closest material to enamel which is why when great results are achieved they can look very natural in comparison to other materials. The process for porcelain veneers includes the drilling of the natural teeth in order to create space which enables them to be fitted. If this technique is not carried out carefully the risk to the health of the natural teeth in the long term can be decreased and the lifespan of the veneers will be minimised. Porcelain veneers typically last around 10-15 yrs on average but they may last longer or shorter depending on the ongoing aftercare.",
        imageSrc: "/BA-5.png",
        imageAlt: "Porcelain Veneers Process"
      }
      // Additional feature sections for 'Porcelain Veneers' can be added here
    ]
  },
  {
    name: "Dental Crowns",
    features: [
      {
        name: "Dental Crowns Overview",
        description:
          "A Dental Crown is a full coverage restoration. Crowns are used to reinforce and rebuild damaged/weakened teeth or in some cases to improve appearance. Many different materials can be used to make dental crowns including porcelain and metal. Once a dental crown is placed it should look, feel and function like any other tooth.",
        imageSrc: "/BA-6.png",
        imageAlt: "Dental Crowns"
      }
      // Additional feature sections for 'Dental Crowns' can be added here
    ]
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DentalServicesPage() {
  const coordinates = {
    lat: 48.8584,
    lng: 2.2945,
  };
  

  return (
    <div className="bg-white">
      <section
        aria-labelledby="features-heading"
        className="mx-auto max-w-7xl py-32 sm:px-2 lg:px-8"
      >
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
          <div className="max-w-3xl">
          <h2
          id="features-heading"
          className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
        >
          Dental Services
        </h2>
        <p className="mt-4 font-Roboto text-gray-500">
          Dr Nigel Hargreaves and the Hargreaves Dentistry team provide top-tier dental treatments, ensuring every patient receives the utmost care. Explore our range of services to achieve the smile you've always desired.
        </p> 
        
          </div>

          <Tab.Group as="div" className="mt-4">
            <div className="-mx-4 flex overflow-x-auto sm:mx-0">
              <div className="flex-auto border-b border-gray-200 px-4 sm:px-0">
                <Tab.List className="-mb-px flex space-x-10">
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.name}
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? "border-black font-Roboto   text-black"
                            : "border-transparent font-Roboto text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap font-Roboto focus:none outline-none select-none border-b-2 py-6 text-sm font-medium"
                        )
                      }
                    >
                      {tab.name}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
            </div>

            <Tab.Panels as={Fragment}>
              {tabs.map((tab) => (
                <Tab.Panel key={tab.name} className="space-y-16 pt-10 lg:pt-16">
                  {tab.features.map((feature) => (
                    <div
                      key={feature.name}
                      className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8"
                    >
                      <div className="mt-6 lg:col-span-5 lg:mt-0">
                        <h3 className="text-lg font-medium font-Roboto text-gray-900">
                          {feature.name}
                        </h3>
                        <p className="mt-2 text-sm font-Roboto text-gray-500">
                          {feature.description}
                        </p>
                      </div>
                      <div className="lg:col-span-7">
                        <div className="aspect-h-1 aspect-w-2 overflow-hidden shadow-md bg-gray-100 sm:aspect-h-2 sm:aspect-w-5">
                          <img
                            src={feature.imageSrc}
                            alt={feature.imageAlt}
                            className="object-cover object-center"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </section>
      <Testimonials/>
      <div className="">
        <div className="  mx-auto py-24 sm:py-32 max-w-7xl px-6  lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold  text-gray-900 sm:text-4xl">
            Yorkshire Dental Suite
            </h2>
          </div>
        </div>
      </div>
      <div>
    </div>
      <TreatmentEnquiryForm/>
<FAQs/>
    </div>
  );
}
