/* eslint-disable no-unused-vars */
import { Fragment, useContext } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useEffect } from "react";
import axios from "axios"
import AuthContext from "../../context/AuthContext";

const userNavigation = [
  { name: "Account Settings", href: "/settings" },
];
const navigation = [
  { name: "Dental Services", href: "/dental-services" },
  { name: "About", href: "/about" },
  { name: "Academy", href: "/academy" },
  { name: "News", href: "/news" },
  { name: "Contact", href: "/contact" },
];

function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  function classNames(...classes) {
    return classes.filter(Boolean).join("  ");
  }
  const [isMobile, setIsMobile] = useState(false);
  function logOut() {
    axios
      .post("https://api.nigelhargreaves.co.uk/user/logout/", {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.success) {
          setUser(null);
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    let isMounted = true;
    isMounted && user ? setLoggedIn(true) : setLoggedIn(false);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 960); // Adjust the breakpoint as needed
    };
    
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {isMobile ? (
        <>
          <div className=" top-0 fixed w-full  bg-white">
            <div className="flex justify-between  px-8 py-6">
              <div className="flex lg:flex-1">
                <Link to="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">
                    Nigel Hargreaves Dentist Dentistry Dental Services Dental
                    Academy Nigel Dental Academy{" "}
                  </span>
                  <img
                    className="h-8 w-auto"
                    height={10}
                    width={100}
                    src="/hargreaves-logo.png"
                    alt="website logo - nigel hargreaves"
                  />
                </Link>
              </div>
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div>
            <Dialog
              as="div"
              className="lg:hidden"
              open={mobileMenuOpen}
              onClose={setMobileMenuOpen}
            >
              <div className="fixed inset-0 z-50" />
              <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                  <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Your Company</span>
                    <img
                      className="h-8 w-auto"
                      height={10}
                      width={100}
                      src="/hargreaves-logo.png"
                      alt="website logo - nigel hargreaves"
                    />
                  </a>
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 ">
                    <div className="space-y-2 py-6">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-Roboto leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    {user !== null ? (
                      <>
                        <div className="  flex items-center  ">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full bg-gray-50"
                            src={user?.profile_picture}
                            alt=""
                          />
                          <span className=" flex items-center">
                            <span
                              className="ml-4 text-base font-Roboto leading-6 text-gray-900"
                              aria-hidden="true"
                            >
                              {user?.name}
                            </span>
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="py-6">
                          <Link
                            to="/login"
                            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-Roboto leading-7 text-[#B3B4B4] "
                          >
                            Log in
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </div>
        </>
      ) : (
        <header className="w-full bg-white shadow-lg shadow-gray-400/10 fixed inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">
                  Nigel Hargreaves Dentist Dentistry Dental Services Dental
                  Academy Nigel Dental Academy{" "}
                </span>
                <img
                  className="h-8 w-auto"
                  height={10}
                  width={100}
                  src="/hargreaves-logo.png"
                  alt="website logo - nigel hargreaves"
                />
              </Link>
            </div>

            <div className=" flex gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-Roboto leading-6 text-[#B3B4B4] hover:text-black "
                >
                  {item.name}
                </a>
              ))}
            </div>
            {user !== null ? (
              <div className="">
                <Menu as="div" className="relative ">
                  <Menu.Button className="-m-1.5  flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 ml-12 rounded-full bg-gray-50"
                      src={user.profile_picture}
                      alt=""
                    />
                    <span className=" flex items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                        aria-hidden="true"
                      >
                        {user.name}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right bg-white py-2 shadow-sm ">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                       <Menu.Item>
                          {({ active }) => (
                            <p
                              onClick={() => logOut()}
                              className={classNames(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              Log Out
                            </p>
                          )}
                        </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ) : (
              <div className="flex flex-1 justify-end">
                <Link
                  to="/login"
                  className="text-sm font-Roboto leading-6 text-[#B3B4B4] hover:text-black"
                >
                  Log in <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            )}
          </nav>
        </header>
      )}
    </div>
  );
}

export default Navbar;
