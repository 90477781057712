/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { GrMail } from "react-icons/gr";
import { FaPhoneAlt } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";
import FAQs from "../FAQs";
import FAQAbout from "../FAQAbout";

const faqs = [
  {
    id: 1,
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    id: 2,
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    id: 3,
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },

  // More questions...
]

const features = [
  {
    name: "Patient Journey    ",
  },
  { name: "How to set fees" },
  {
    name: "Essential equipment",
  },
  {
    name: "Implant systems and support from reps",
  },
  { name: "Which cases to attempt" },
  { name: "How to sell and market dental implants" },
  {
    name: "Nursing/FoH/TCO support",
  },
];

const timeline = [
  {
    name: "Founded company",
    description:
      "Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.",
    date: "Aug 2021",
    dateTime: "2021-08",
  },
  {
    name: "Secured $65m in funding",
    description:
      "Provident quia ut esse. Vero vel eos repudiandae aspernatur. Cumque minima impedit sapiente a architecto nihil.",
    date: "Dec 2021",
    dateTime: "2021-12",
  },
  {
    name: "Released beta",
    description:
      "Sunt perspiciatis incidunt. Non necessitatibus aliquid. Consequatur ut officiis earum eum quia facilis. Hic deleniti dolorem quia et.",
    date: "Feb 2022",
    dateTime: "2022-02",
  },
  {
    name: "Global launch of product",
    description:
      "Ut ipsa sint distinctio quod itaque nam qui. Possimus aut unde id architecto voluptatem hic aut pariatur velit.",
    date: "Dec 2022",
    dateTime: "2022-12",
  },
];
const jobOpenings = [
  {
    id: 1,
    role: "Full-time designer",
    href: "#",
    description:
      "Quos sunt ad dolore ullam qui. Enim et quisquam dicta molestias. Corrupti quo voluptatum eligendi autem labore.",
    salary: "$75,000 USD",
    location: "San Francisco, CA",
  },
  {
    id: 2,
    role: "Laravel developer",
    href: "#",
    description:
      "Et veniam et officia dolorum rerum. Et voluptas consequatur magni sapiente amet voluptates dolorum. Ut porro aut eveniet.",
    salary: "$125,000 USD",
    location: "San Francisco, CA",
  },
  {
    id: 3,
    role: "React Native developer",
    href: "#",
    description:
      "Veniam ipsam nisi quas architecto eos non voluptatem in nemo. Est occaecati nihil omnis delectus illum est.",
    salary: "$105,000 USD",
    location: "San Francisco, CA",
  },
];

export default function About() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <main className="isolate">
      {/* Hero section */}
      <div className="relative isolate -z-10 overflow-hidden pt-14">
        {/* ... (Other divs retained for structure, won't be modified in this answer for brevity) ... */}
        <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-16">
            <h1 className="max-w-2xl text-3xl font-bold text-gray-900 sm:text-4xl lg:col-span-2 xl:col-auto">
              Story And Passion For Dentistry
            </h1>
            <div className="mt-6 max-w-xl font-Roboto lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-md leading-8 text-gray-600">
                Dentistry is Dr Nigel’s passion. He believes in the importance
                of creating beautiful smiles whilst preserving the health of
                your teeth. He offers all aspects of restorative and cosmetic
                dentistry from the Yorkshire Dental Suite in Leeds. He has
                studied and trained with some of the world’s leading clinicians
                and academics in restorative and aesthetic dentistry.
              </p>
              <p className="text-md mt-6 leading-8 text-gray-600">
                He qualified from the University of Sheffield in 19?? And has
                subsequently completed a Masters and …………from the ……. He is a
                member of the ……. In addition to his daily clinics he is the
                founder of the ‘Hargreaves Dental Academy’ whereby he runs
                multiple postgraduate courses covering Restorative Dentistry,
                Implant Dentistry and …….
              </p>
              <p className="text-md mt-6 leading-8 text-gray-600">
                His 12 day modular Restorative Course is one of the UK’s leading
                courses of its kind. It is held in Yorkshire and he has trained
                hundreds of dentists from all over the world since its inception
                in 2008. Alongside this Dr Nigel is a clinical mentor for
                implant clinicians nationwide. He provides training for other
                dentists who are interested in implant dentistry.
              </p>
            </div>
            <img
              src="/Nigel.jpg"
              alt="Dr Nigel Hargreaves"
              className="mt-10 aspect-[8/7] w-full max-w-lg object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
            />
          </div>
        </div>
      </div>
      {/* Content section */}
      <div className="bg-[#F7F7F7] overflow-hidden ">
        <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8 py-24 sm:py-32">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
            <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
              <h2 className="text-3xl font-bold  text-gray-900 sm:text-4xl">
                Mentoring Services
              </h2>
              <p className="mt-6 text-md font-Roboto leading-8 text-gray-600">
                He will come to your practice with his nurse and implant
                equipment? And assess, plan and treat your implant patients with
                you and you can be as involved as much or as little as you want.
              </p>
              <p className="mt-6 text-md font-Roboto leading-8 text-gray-600">
                With the mentoring/coaching Dr Nigel and his team can help with
                any logistical/treatment planning or clinical concerns relating
                to dental implants including:
              </p>
              <div className="pt-8">
                {features.map((feature) => (
                  <div key={feature.name} className="relative  pl-9">
                    <dt className="ftext-md font-Roboto leading-8 text-gray-600">
                      <CheckIcon
                        className="absolute left-0 top-1 h-5 w-5 text-black"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
              <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                <img
                  src="https://images.unsplash.com/photo-1670272502246-768d249768ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80"
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none bg-gray-50 object-cover"
                />
              </div>
              <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                  <img
                    src="https://images.unsplash.com/photo-1605656816944-971cd5c1407f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"
                    alt=""
                    className="aspect-[4/3] w-[24rem] max-w-none flex-none bg-gray-50 object-cover"
                  />
                </div>
                <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                  <img
                    src="https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&h=842&q=80"
                    alt=""
                    className="aspect-[7/5] w-[37rem] max-w-none flex-none bg-gray-50 object-cover"
                  />
                </div>
                <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                  <img
                    src="https://images.unsplash.com/photo-1612872087720-bb876e2e67d1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"
                    alt=""
                    className="aspect-[4/3] w-[24rem] max-w-none bg-gray-50 object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Stats */}
      <div className="mx-auto py-24 sm:py-32 max-w-7xl px-6  lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold  text-gray-900 sm:text-4xl">
            Tutoring Experience
          </h2>
          <p className="mt-6 text-md font-Roboto leading-8 text-gray-600">
            Nigel has been involved in teaching and lecturing since 2003. Both
            as a national and international guest lecturer, but predominantly as
            part of his own teaching academy, which he founded in 2008. Here,
            Nigel has run restorative dentistry and dental implantology courses
            in a combination of formats, ranging from single day courses, to
            12-month long modular courses. These courses are now being combined
            with the Yorkshire Dental Suite Academy, where we are able to offer
            courses in Restorative and Cosmetic Dentistry, Oral Surgery and
            Dental Implantology.
          </p>
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
          <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
            <p className="flex-none text-3xl font-bold  text-gray-900">250k</p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold font-Roboto text-gray-900">
                Users on the platform
              </p>
              <p className="mt-2 text-base leading-7 font-Roboto text-gray-600">
                Vel labore deleniti veniam consequuntur sunt nobis.
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 bg-[#181818] p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
            <p className="flex-none text-3xl font-bold  text-white">
              $8.9 billion
            </p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold font-Roboto text-white">
                We’re proud that our customers have made over $8 billion in
                total revenue.
              </p>
              <p className="mt-2 text-base leading-7 font-Roboto text-gray-400">
                Eu duis porta aliquam ornare. Elementum eget magna egestas.
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 bg-[#333333] p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
            <p className="flex-none text-3xl font-bold  text-white">401,093</p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold font-Roboto text-white">
                Transactions this year
              </p>
              <p className="mt-2 text-base leading-7 font-Roboto text-gray-200">
                Eu duis porta aliquam ornare. Elementum eget magna egestas. Eu
                duis porta aliquam ornare.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Dentistry Experience & Qualifications */}
      <div className="bg-[#F7F7F7]">
        <div className="  mx-auto py-24 sm:py-32 max-w-7xl px-6  lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold  text-gray-900 sm:text-4xl">
              Dentistry Experience & Qualifications
            </h2>
          </div>
        </div>
      </div>

      {/* Dentistry Experience & Qualifications */}
      <div className="relative bg-white">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2 " />
        </div>
        <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
          <div className=" px-6 py-16 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="mx-auto max-w-lg">
              <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                Mentoring Service Enquiry Form
              </h2>
              <p className="mt-3 text-md font-Roboto leading-6 text-gray-500">
                Contact Joanne O’Shea - Course Coordinator / PA for any
                inquiries or information.
              </p>
              <dl className="mt-8 text-base font-Roboto text-gray-800">
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <GrMail
                      className="h-5 w-5 flex-shrink-0 "
                      aria-hidden="true"
                    />
                    <span className="ml-3">joanne@nigelhargreaves.co.uk</span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <FaPhoneAlt
                      className="h-5 w-5 flex-shrink-0 "
                      aria-hidden="true"
                    />
                    <span className="ml-3">07508794114</span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Instagram</dt>
                  <dd className="flex">
                    <PiInstagramLogoFill
                      className="h-6 w-6 flex-shrink-0 "
                      aria-hidden="true"
                    />
                    <span className="ml-2">@drnigelhargreaves</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white sm:px-0  sm:py-6 lg:col-span-3 lg:px-8 lg:py-24 xl:pl-12">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <form action="#" method="POST" className="px-6 lg:px-8 ">
                <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                  <div className="">
                    <label
                      htmlFor="name"
                      className="block text-sm font-Roboto leading-6 text-gray-900"
                    >
                      Name (as per GDC register)
                    </label>
                    <input
                  
                      id="name"
                      name="name"
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                    />

                    <label
                      htmlFor="email"
                      className="block text-sm font-Roboto leading-6 mt-4 text-gray-900"
                    >
                      Email address
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                    />

                    <label
                      htmlFor="gdcNumber"
                      className="block text-sm font-Roboto leading-6 mt-4 text-gray-900"
                    >
                      GDC Number
                    </label>
                    <input
                      id="gdcNumber"
                      name="gdcNumber"
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                    />

                    <label
                      htmlFor="phone"
                      className="block text-sm font-Roboto leading-6 mt-4 text-gray-900"
                    >
                      Contact telephone number
                    </label>
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                    />

                    <label
                      htmlFor="country"
                      className="block text-sm font-Roboto leading-6 mt-4 text-gray-900"
                    >
                      Practice Location Details
                    </label>
                    <input
                      id="country"
                      name="country"
                      type="text"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                    />

                    <label
                      htmlFor="message"
                      className="block text-sm font-Roboto leading-6 mt-4 text-gray-900"
                    >
                      Message
                    </label>
                    <div className="mt-2.5">
                      <textarea
                        name="message"
                        id="message"
                        rows={4}
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                        defaultValue={""}
                      />
                    </div>

                    <label
                      for="link"
                      class="block text-sm font-Roboto leading-6 mt-4 text-gray-900"
                    >
                      Add a link for case details Eg: Xrays, scans, images etc

                    </label>
                    <input
                      id="link"
                      name="link"
                      type="url"
                      required
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6 mt-2"
                    />

                    <div className="mt-4 flex items-center">
                      <input
                        id="consent"
                        name="consent"
                        type="checkbox"
                        required
                        className="mr-2"
                      />
                      <label
                        htmlFor="consent"
                        className="text-sm font-Roboto leading-6 text-gray-900"
                      >
                        I allow 
                      </label>
                    </div>
                  </div>
                  <div className="mt-8 flex justify-end">
                    <button
                      type="submit"
                      className="rounded-md w-full bg-black hover:bg-[#404040] px-3.5 py-2.5 text-center text-sm font-Roboto text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Send message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
 {/* FAQs */}
  <FAQAbout/>
    </main>
  );
}
