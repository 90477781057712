import axios from "axios";

const BASE_URL = "http:/localhost:1337";
export default axios.create({
  BASE_URL,
});

export const axiosPrivate = axios.create({
  BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
