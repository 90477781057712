/* eslint-disable react/prop-types */
import { RxCross2 } from "react-icons/rx";
const Alert = (props) => {
  setTimeout(() => {
    props.setAlertMessage("");
  }, 8000);
  return (
    <div
      className={`${
        props.message === "" && "hidden"
      } text-red-500 absolute top-5 right-0 w-[300px] flex flex-col`}
      role="alert"
    >
      <div className="text-black w-full flex justify-end" onClick={() => props.setAlertMessage("")}>
        <RxCross2 />
      </div>
      <div className="w-full">{props.message}</div>
    </div>
  );
};

export default Alert;
