import { Link } from "react-router-dom";

// eslint-disable-next-line react/prop-types
function VerifyOTP({ updateFields, otp, email, back, getOTP }) {
  return (
    <div className="flex flex-1 flex-col mt-60  px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Link to="/">
          <img
            className="mx-auto h-10 w-auto"
            src="/hargreaves-logo-icon.png"
            alt="Your Company"
          />
        </Link>
        <h2 className="mt-10 text-center text-2xl font-bold  text-gray-900">
          Verify your email
        </h2>
      </div>
      <div className="pt-4 flex flex-col">
      <p className="font-Roboto pt-2 text-[#7A7A7A]">
      Incorrect email?
      <button className="ml-2 text-black" type="button" onClick={() => back()}>
        Go back
      </button>
    </p>
        <p className="font-Roboto mt-4">Email: {email}</p>

        <button
          className="text-right mt-2 font-Roboto items-end justify-end "
          type="button"
          onClick={() => getOTP({ email: email })}
        >
          Resend OTP
        </button>
      </div>

      <div className="mt-2 mb-4">
        <input
          id="otp"
          placeholder="Enter OTP"
          name="otp"
          type="text"
          value={otp}
          onChange={(event) => updateFields({ otp: event.target.value })}
          autoComplete="otp-new"
          required
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
}

export default VerifyOTP;
