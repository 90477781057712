import React from 'react';

const dentalServices = [
  {
    name: 'Dental Implants',
    description: 'Replace missing teeth and restore your smile with dental implants.',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-feature-08-detail-02.jpg',
    imageAlt: 'Dental implant model',
  },
  {
    name: 'Same Day Teeth/Full arch Implants',
    description: 'Get a full set of teeth replaced in just a single day.',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-feature-08-detail-02.jpg',
    imageAlt: 'Model of full arch implants',
  },
  {
    name: 'Porcelain Veneers',
    description: 'Enhance your smile with custom-made porcelain veneers.',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-feature-08-detail-02.jpg',
    imageAlt: 'Porcelain veneer model',
  },
  {
    name: 'Crowns & Onlays',
    description: 'Strengthen and improve the appearance of your teeth.',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-feature-08-detail-02.jpg',
    imageAlt: 'Dental crowns and onlays',
  },
];

const dentalServicesagain = [
  {
    name: 'Bridges',
    description: 'Replace missing teeth and restore your bite with the best dental bridges.',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-feature-08-detail-03.jpg',
    imageAlt: 'Dental bridge model',
  },
  {
    name: 'Smile Makeovers',
    description: 'Transform your smile with our comprehensive makeover services.',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-feature-08-detail-02.jpg',
    imageAlt: 'Smile transformation imagery',
  },
  {
    name: 'Teeth Whitening',
    description: 'Brighten your smile with our professional teeth whitening services.',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-feature-08-detail-03.jpg',
    imageAlt: 'Teeth whitening kit',
  },
];

export default function DentalServices() {
  return (
    <div className="bg-[#F7F7F7]">
      <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <div className="max-w-3xl">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Dental Services: Comprehensive Dental Care
          </p>
          <p className="mt-4 font-Roboto text-gray-500">
            Explore our range of dental services and ensure your smile remains vibrant and healthy.
          </p>
        </div>
        <div className="">
        <div className="mt-11 grid grid-cols-1 items-start gap-x-6 gap-y-16 sm:mt-16 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8">
          {dentalServices.map((service) => (
            <div key={service.name} className="flex flex-col-reverse">
              <div className="mt-6">
                <h3 className="text-md font-medium font-Roboto text-gray-900">{service.name}</h3>
                <p className="mt-2 text-[0.85rem] font-Roboto text-gray-500">{service.description}</p>
              </div>
              <div className="aspect-h-1 aspect-w-1 overflow-hidden shadow-md ">
                <img src={service.imageSrc} alt={service.imageAlt} className="object-cover object-center" />
              </div>
            </div>
          ))}
        </div>
        <div className="mt-11 grid grid-cols-1 justify items-center gap-x-6 gap-y-16 sm:mt-16 sm:grid-cols-2 lg:grid-cols-3 lg:gap-x-8">
          {dentalServicesagain.map((service) => (
            <div key={service.name} className="flex flex-col-reverse">
              <div className="mt-6">
                <h3 className="text-md font-medium font-Roboto text-gray-900">{service.name}</h3>
                <p className="mt-2 text-[0.85rem] font-Roboto text-gray-500">{service.description}</p>
              </div>
              <div className="aspect-h-1 aspect-w-1 overflow-hidden shadow-md ">
                <img src={service.imageSrc} alt={service.imageAlt} className="object-cover object-center" />
              </div>
            </div>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
}
