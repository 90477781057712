/* eslint-disable react/jsx-key */
// This is the stepper form register form

import { useContext, useState } from "react";
import { useMultistepForm } from "../utils/hooks/useMultiStepForm";
import RegisterForm from "./form/Auth/Register";
import VerifyOTP from "./form/Auth/VerifyOTP";
import axios from "axios";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const INIT_DATA = {
  name: "",
  email: "",
  password: "",
  otp: "",
  messageId: "",
};

const Register = () => {
  const [data, setData] = useState(INIT_DATA);
  const { user, setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { step, currentStepIndex, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <RegisterForm updateFields={updateFields} {...data} />,
      <VerifyOTP
        updateFields={updateFields}
        {...data}
        back={goback}
        getOTP={getOTP}
      />,
    ]);
  function updateFields(fields) {
    setData((prev) => {
      return { ...prev, ...fields };
    });
  }

  const { setJwtToken } = useOutletContext();
  async function getOTP({ email }) {
    setLoading(true);
    console.log(email);
    if (email)
      axios
        .post("https://api.nigelhargreaves.co.uk/user/verify-email/", {
          email,
        })
        .then((response) => {
          if (response.data) {
            console.log(response.data.messageId);
            updateFields({ messageId: response.data.messageId });
            console.log(data);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
  }
  async function verifyOTP({ email, messageId, otp, password, name }) {
    console.log(email);
    let dataResponse;
    if (email) {
      try {
        const response = await axios.post(
          "https://api.nigelhargreaves.co.uk/user/create-user/",
          {
            email,
            messageId,
            otp,
            password,
            passwordConfirmation: password,
            name,
          }
        );
        dataResponse = response.data;
        return dataResponse;
      } catch (error) {
        console.log(error);
        return error;
      }
    } else {
      return { message: "email not provided" };
    }
  }
  function goback() {
    return back();
  }
  async function onSubmit(e) {
    e.preventDefault();
    console.log(data);
    if (!isLastStep && data.email) {
      await getOTP({ email: data.email });
      return next();
    }
    if (isLastStep) {
      console.log("Lst step", data);
      try {
        if (data.email && data.password && data.messageId && data.otp) {
          const responsedata = await verifyOTP(data);
          console.log(responsedata);
          if (responsedata.success) {
            console.log(responsedata.accessToken);
            setJwtToken(responsedata.accessToken);
            console.log(responsedata.user);
            setUser(responsedata.user);
            navigate("/");
          } else {
            throw new Error("retry");
          }
        }
      } catch (error) {
        console.log(error.message);
        if (error.message === "retry") navigate('/login');
      }
    }
  }
  return (
    <>
      <div className="h-full w-full flex flex-col items-center justify-center">
        <div className="h-[90%] w-[75%] flex flex-col">
          <p className="">{currentStepIndex}</p>
          {loading && (
            <div className="loading-overlay">
              <p>Loading...</p>
            </div>
          )}
          <form
            className="sm:mx-auto sm:w-full sm:max-w-sm"
            onSubmit={onSubmit}
          >
            {step}
            <div className="px-8">
              <button
                type="submit"
                className="flex sm:w-full max-w-sm justify-center rounded-md bg-black px-3 py-1.5 text-sm font-Roboto leading-6 text-white shadow-sm hover:bg-[#404040] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {isFirstStep ? "Create account" : "Confirm"}
              </button>
            </div>
          </form>
          <p className="mt-6 text-center font-Roboto text-base text-[#A3A3A3]">
            Already have an account?{" "}
            <Link to="/login">
              <p className="font-Roboto leading-6 text-black cursor-pointer">
                Sign In
              </p>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Register;
