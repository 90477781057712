
import { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [agreed, setAgreed] = useState(false)
  const [date, setDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);

  const handleDateChange = (newDate) => {
    setDate(newDate);
    setShowCalendar(false);
  };

  return (
    <div className="isolate bg-[#F7F7F7] px-6 py-24 sm:py-32 lg:px-8">
      <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true"
      >
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Treatment Enquiry
</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Fill this form for any treatment enquiries and we will get back to you.
        </p>
      </div>
      <form action="#" method="POST" className="mx-auto mt-8 max-w-xl sm:mt-10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div className="sm:col-span-2">
            <label htmlFor="company" className="block text-sm font-semibold font-Roboto   leading-6 text-gray-900">
              Your Name
            </label>
            <div className="mt-1.5">
              <input
          
                type="text"
                required
                name="company"
                id="company"
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-semibold font-Roboto   leading-6 text-gray-900">
              Email
            </label>
            <div className="mt-1.5">
              <input
                type="email"
                required
                name="email"
                id="email"
                autoComplete="email"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="phone-number"
              className="block text-sm font-semibold font-Roboto  leading-6 text-gray-900"
            >
              Phone number
            </label>
            <div className="relative mt-1.5">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="country" className="sr-only">
                  Phone number
                </label>
                <div
                  id="country"
                  name="country"
                  className="h-full rounded-md border-0 bg-transparent bg-none py-2 pl-3 pr-3 text-gray-500  sm:text-md "
                >
                  +44
                </div>
              </div>
              <input
                type="tel"
                required
                name="phone-number"
                id="phone-number"
                autoComplete="tel"
                className="block w-full rounded-md border-0 px-3.5 py-2 pl-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-md sm:leading-6"
              />
            </div>
          </div>
          <div className=" relative ">
            <label htmlFor="first-name" className="block text-sm font-semibold font-Roboto   leading-6 text-gray-900">
              Date of birth
            </label>
            <div className="mt-1.5">
              <input
                 type="text"
                 required
                 value={date.toDateString()}
                 readOnly
                 onClick={() => setShowCalendar(!showCalendar)}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
              />
               {showCalendar && (
                <div className=" w-full absolute top-20 left-0 z-10 bg-white  rounded-[10px] shadow-sm ">
                  {/* Add custom styles to the calendar component */}
                  <Calendar
                    onChange={handleDateChange}
                    value={date}
                    className="custom-calendar"
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-semibold font-Roboto   leading-6 text-gray-900">
              Addres Pincode
            </label>
            <div className="mt-1.5">
              <input
                type="text"
                required
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
      <label htmlFor="location" className="block text-sm font-semibold font-Roboto   leading-6 text-gray-900">
        What treatment are you looking for?
      </label>
      <select
        id="location"
        required
        name="location"
        className="block w-full mt-2 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
        defaultValue="Select a treatment"
      >
        <option>Select a treatment</option>
        <option>Dental Implants</option>
        <option>Same day Implants</option>
        <option>Porcelain Veneers</option>
        <option>Dental Crowns</option>
        <option>Bridges</option>
        <option>Smile Makeover</option>
        <option>Composite Bonding</option>
        <option>Teeth Whitening</option>
      </select>
    </div>
    <div className="sm:col-span-2">
      <label htmlFor="location" className="block text-sm font-semibold font-Roboto   leading-6 text-gray-900">
        Where did you hear about us?
      </label>
      <select
        id="location"
        required
        name="location"
        className="block w-full mt-2 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
        defaultValue="Select an option"
      >
             <option>Select an option</option>
        <option>Search engine (Google, Bing, etc.)n</option>
        <option>Social media (Facebook, Instagram, Twitter, etc.)</option>
        <option>Online advertising (Google Ads, Facebook Ads, etc.)</option>
        <option>Word-of-mouth</option>
        <option>Referral from another dentists</option>
        <option>Referral from a friend or family member</option>
   

      </select>
    </div>
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-semibold font-Roboto   leading-6 text-gray-900">
              Message
            </label>
            <div className="mt-1.5">
              <textarea
              required
                name="message"
                id="message"
                rows={4}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                defaultValue={''}
              />
            </div>
          </div>
        </div>
        <div className="my-6">

          <div className="flex items-center mb-4">
            <input  type="checkbox" id="dataUse1" name="dataUse1" />
            <label className="ml-2 text-gray-700" htmlFor="dataUse1">
              I agree to Hargreaves Dentistry/Academy using my personal data to
              provide me with information regarding dental treatments.
            </label>
          </div>
          <div className="flex items-center">
            <input type="checkbox" id="dataUse2" name="dataUse2" />
            <label className="ml-2 text-gray-700" htmlFor="dataUse2">
              I agree to Hargreaves Dentistry/Academy using my personal data to
              keep me informed about marketing offers and initiatives that may
              be of interest.
            </label>
          </div>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="block w-full rounded-md bg-black px-3.5 py-2.5 text-center text-sm font-semibold font-Roboto   text-white shadow-sm hover:bg-[#404040] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Send Enquiry
          </button>
        </div>
      </form>
    </div>
  )
}
