import { useEffect, useState } from "react";
import axios from "../api/axios";
import useRefreshToken from "../utils/hooks/useRefreshToken";

const Users = () => {
  const [users, setUsers] = useState();
  const refresh = useRefreshToken();
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
      try {
        const response = await axios.get(
          "https://api.nigelhargreaves.co.uk/user/get-all-users",
          {
            signal: controller.signal,
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log(response.data);
        isMounted && setUsers(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    getUsers();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <article className="text-black pt-[6rem] h">
      <h2>USERS</h2>
      {users?.length ? (
        <ul>
          {users.map((user, i) => (
            <li key={i}>{user?.name} </li>
          ))}
        </ul>
      ) : (
        ""
      )}

      <button onClick={() => refresh()}>Refresh</button>
    </article>
  );
};

export default Users;
