import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function NewsArticle() {
  const { id } = useParams();
  const navigate = useNavigate();
  console.log(id);
  return <div className="flex flex-col">NewsArticle
     <button onClick={() => navigate(-1)}>go back</button>
    News Article No: {` `}
    {id}</div>;
}

export default NewsArticle;
