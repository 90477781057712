import AboutNigel from "./AboutNigel";
import Blog from "./Blog";
import Contact from "./Contact";
import CourseSummary from "./CourseSummary";
import DentalServices from "./DentalServices";

const posts = [
  {
    id: 1,

    imageUrl:"/smile2.jpeg"
  },
  {
    id: 2,

    imageUrl:
    "/smile1.jpeg"
  },
  {
    id: 3,

    imageUrl:
    "/smile3.jpeg"
  }
];

const Home = () => {
  return (
    <>
      <div className="bg-white flex w-full justify-center items-center">
        <div className=" max-w-4xl py-28 sm:py-44 lg:py-32">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
          <div className="text-center">
            <h1 className="text-4xl font-bold text-gray-900 sm:text-6xl lead">
              Start your journey into the world of private dentistry
            </h1>
            <p className="mt-6 text-lg font-Roboto text-gray-600">
            Take your dentistry to the next level.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/register"
                className="rounded-md bg-black px-3.5 py-2.5 text-sm font-Roboto text-white shadow-sm hover:bg-[#404040] "
              >
                Get started
              </a>
              <a
                href="/Academy"
                className="text-sm font-Roboto text-black hover:text-neutral-600"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="relative isolate  flex flex-col justify-end overflow-hidden mx-8 bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
              >
                <img
                  src={post.imageUrl}
                  alt=""
                  className="absolute inset-0 -z-10 h-full w-full object-cover"
                />
              </article>
            ))}
          </div>
        </div>
      </div>


      <CourseSummary/>
       <AboutNigel/>
       <DentalServices/>
       {/*<Blog/>*/}
       <Contact/>
    </>
  );
};

export default Home;
