import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./components/ErrorPage.jsx";
import Home from "./components/Home.jsx";
import Course from "./components/Course.jsx";
import AddCourse from "./components/AddCourse.jsx";

import Courses from "./components/Courses.jsx";
import Login from "./components/Login.jsx";
import Contact from "./components/static/Contact.jsx";
import NewsArticle from "./components/NewsArticle.jsx";
import Register from "./components/Register2.jsx";
import About from "./components/static/About.jsx";
import Academy from "./components/static/Academy.jsx";
import { AuthProvider } from "./context/AuthContext.jsx";
import Users from "./components/Users.jsx";import Settings from "./components/Settings.jsx";
import DentalServicesPage from "./components/static/DentalServicesPage.jsx";
import Blog from "./components/Blog.jsx";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "/courses",
        element: <Courses />,
      },
      {
        path: "/news",
        element: <Blog />,
      },
      {
        path: "/about",
        element: <About />,
      },
       {
        path: "/Users",
        element: <Users />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/news-article/:id",
        element: <NewsArticle />,
      },
      {
        path: "/course/:id",
        element: <Course />,
      },
      {
        path: "/admin/course/:id",
        element: <AddCourse />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/academy",
        element: <Academy />,
      },
      {
        path: "/settings",
        element: <Settings />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/dental-services",
        element: <DentalServicesPage />
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);
