import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div className="">
      <div className="container">
        <h1>Error Occured</h1>
        <p>Sorry an unexpected error occured</p>
        <p>
          <em>{error.statusText || error.message}</em>
        </p>
      </div>
    </div>
  );
}
