import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import AuthContext from "../context/AuthContext";
const Login = () => {
  const emailRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setJwtToken } = useOutletContext();
  const { setAlertClassName } = useOutletContext();
  const { setAlertMessage } = useOutletContext();
  const { setUser } = useContext(AuthContext);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("email/pass", email, password);
    if (email && password) {
      axios
        .post(
          "https://api.nigelhargreaves.co.uk/user/login-user/",
          {
            email,
            password,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.data) {
            console.log(response.data.accessToken);
            setJwtToken(response.data.accessToken);
            console.log(response.data.user);
            setUser(response.data.user);
            navigate("/");
          }
        })
        .catch((err) => console.log(err));
    } else {
      setAlertClassName("text-red-600");
      setAlertMessage("Invalid creds");
    }
  };
  return (
    <div className="flex  flex-1 flex-col justify-center px-6 py-12 lg:px-8 place-items-center  h-screen overflow-y-hidden">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Link to="/">
          <img
            className="mx-auto h-10 w-auto"
            src="/hargreaves-logo-icon.png"
            alt="Your Company"
          />
        </Link>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-Roboto leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                ref={emailRef}
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                autoComplete="email-new"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-Roboto leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="text-sm">
                <a
                  href="#"
                  className="font-Roboto text-[#A3A3A3] hover:text-BLACK"
                >
                  Forgot password?
                </a>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                onChange={(event) => setPassword(event.target.value)}
                autoComplete="password-new"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-Roboto leading-6 text-white shadow-sm hover:bg-[#404040] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </button>
          </div>
        </form>

        <p className="mt-6 text-center text-sm font-Roboto text-[#A3A3A3]">
          Don&apos;t have an account?{" "}
          <Link to="/register">
            <span className=" leading-6 text-black ">Register Now</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
