import { CheckIcon } from "@heroicons/react/20/solid";

const features = [
  {
    name: "Advanced Dentistry Skills",
    description:
      "This course will improve your skills and knowledge, but more importantly, give you the confidence to take your dentistry to the next level."
  },
  {
    name: "Latest Clinical Techniques",
    description:
      "Learn the latest clinical techniques and enhance your clinical skills."
  },
  {
    name: "Restorative Programme Overview",
    description:
      "The Restorative Programme is aimed to provide dentists with the knowledge, skills, understanding and confidence to apply to your everyday practice."
  },
  {
    name: "Intensive Learning Structure",
    description:
      "The 6 month programme consists of 12 days practical hands-on sessions and interactive lectures all within fantastic state of the art facilities."
  },
  {
    name: "Skill Development and Modern Techniques",
    description:
      "The programme will enable delegates to learn new skills and the latest techniques whilst developing confidence and communication skills."
  },
  {
    name: "Ongoing Clinical Support",
    description:
      "We pride ourselves on providing with ongoing clinical support after the completion of our programmes by offering them continual access to clinical advice and knowledge."
  }
];

export default function CourseSummary() {
  return (
    <div className="bg-[#F7F7F7] py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-Roboto font-medium leading-7 text-gray-500">
              Our Dentistry Course
            </h2>
            <p className="mt-2 text-3xl font-bold text-gray-900 sm:text-4xl">
              The Ultimate Dental Learning Hub
            </p>
            <p className="mt-6 text-base font-Roboto leading-7 text-gray-600">
              At the Hargreaves Dental Academy, we're not just about teaching;
              we're about transforming careers. With state-of-the-art facilities
              and renowned educators, delve deep into cutting-edge dental
              techniques and practices, ensuring you remain at the forefront of
              dental excellence.
            </p>
          </div>

          <dl className="col-span-2 grid grid-cols-1  text-base text-gray-600 sm:grid-cols-2 ">
            {features.map((feature) => (
              <div key={feature.name} className="relative flex border hover:bg-white hover:shadow-lg  py-5 px-5">
                <div className="  space-x-4">
                <CheckIcon
                    className="h-5 w-5 mt-1  text-gray-500"
                    aria-hidden="true"
                  />
                </div>
                <div className=" pl-4">
                  <div className="font-semibold text-md font-Roboto text-gray-900">
                  {feature.name}
                  </div>
                  <div className="mt-2 text-[0.85rem] font-Roboto">
                  {feature.description}
                  </div>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
