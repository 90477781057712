export default function AboutNigel() {
  return (
    <div className="mx-auto max-w-7xl py-24 flex justify-center px-6 lg:px-8">
          <li className="flex flex-col gap-10 sm:flex-row">
            <img
              className="aspect-[4/5] w-80 flex-none object-cover shadow-lg"
              src="/Nigel.jpg"
              alt=""
            />
            <div className="max-w-2xl flex-auto py-2 lg:py-20">
              <h3 className="text-3xl font-bold leading-8 pb-2 text-gray-900">
                Dr Nigel Hargreaves
              </h3>
              <p className="text-base font-Roboto  text-gray-600">
                Restorative & Implant Dentist
              </p>
              <p className="mt-6 text-md font-Roboto leading-7 text-gray-600">
                {" "}
                Dentistry is Dr Nigel’s passion. He offers all aspects of
                restorative and cosmetic dentistry. He believes in the
                importance of creating beautiful smiles whilst preserving the
                health of your teeth. Dr Nigel Hargreaves is a highly skilled
                and experienced clinician with a special interest in dental
                implants, restorative and cosmetic dentistry. He carries out his
                work from the Yorkshire Dental Suite in Leeds. He has studied
                and trained with some of the world’s leading clinicians and
                academics in restorative and aesthetic dentistry.
              </p>
            </div>
          </li>
    </div>
  );
}
